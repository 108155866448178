import React, { useContext, useEffect, useMemo, useState } from "react";

import {
  Button,
  Chip,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import SendIcon from "@material-ui/icons/Send";
import { useSnackbar } from "notistack";
import { useMutation } from "react-query";
import {
  inviteUserToScenario,
  removeUserFromScenario,
} from "../../../../api/scenario";
import { JOINT_ACCOUNT_STATUS_LIST } from "../../../../commons/enum";
import ConfirmDeleteModal from "../../../../components/ConfirmDeleteModal";
import LoadingSpinner from "../../../../components/loading-spinner";
import { FinanceContext } from "../../../../context/finance-context";
import ScenarioItemContext from "../../../../context/scenario-item-context";
import {
  useCompanyTheme,
  useQueryInvalidationWithNotification,
} from "../../../../hooks";
import { useJointAccountList } from "../../../../hooks/joint-account.hooks";
import { useMyInfo } from "../../../../hooks/user.hooks.ts";
import { JointScenarioConnectionStatus } from "../../../../types/join-account";
import { checkEmailVerification } from "../../../../utils/email-verification";
import ConfirmSendInvitation from "./confirm-send-invitation";

const JointAccountSelection = ({
  handleChange,
  handleInviteOtherPeople,
  currentConnection,
}) => {
  const [selectedJointAccount, setSelectedJointAccount] = useState("");
  const { data: jointAccountList } = useJointAccountList();
  const { data: myInfo } = useMyInfo();

  const handleSelectJointAccont = (e) => {
    setSelectedJointAccount(e.target.value);
  };
  const renderJointAccountItem = () => {
    return jointAccountList.map((jointAccount) => {
      return (
        <MenuItem
          className="link-property__menu-item"
          key={jointAccount.id}
          value={jointAccount.id}
        >
          {jointAccount.name}
        </MenuItem>
      );
    });
  };
  const renderJointAccountMemberItem = () => {
    const jointAccountDetails = jointAccountList.find(
      (jointAccount) => jointAccount.id === selectedJointAccount
    );
    const memberList = jointAccountDetails?.connections.filter((connection) => {
      return (
        connection.member !== myInfo.id &&
        !currentConnection.some((conn) => conn.email === connection.email) &&
        connection.status === JOINT_ACCOUNT_STATUS_LIST.ACTIVE
      );
    });
    if (memberList?.length > 0) {
      return memberList.map((member) => (
        <MenuItem
          className="link-property__menu-item"
          key={member.id}
          value={member.id}
          onClick={() => handleChange(member.email)}
        >
          {member.email}
        </MenuItem>
      ));
    }
    return (
      <MenuItem className="link-property__menu-item" key="no-data" value="">
        No Member
      </MenuItem>
    );
  };
  return (
    <>
      <div className="link-property">
        <div>Select Joint Account</div>
        <FormControl>
          <Select
            value={selectedJointAccount}
            onChange={handleSelectJointAccont}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            {renderJointAccountItem()}
            <MenuItem
              key="Invite other people"
              value="Invite other people"
              className="link-property__menu-item"
              onClick={() => {
                handleInviteOtherPeople(true);
              }}
            >
              Invite Other People
            </MenuItem>
          </Select>
        </FormControl>
      </div>
      {selectedJointAccount && (
        <div className="link-property">
          <div>Select member</div>
          <FormControl>
            <Select displayEmpty inputProps={{ "aria-label": "Without label" }}>
              {renderJointAccountMemberItem()}
            </Select>
          </FormControl>
        </div>
      )}
    </>
  );
};

const ScenarioInviteInput = ({
  handleChange = () => {},
  value,
  handleBlur = () => {},
  filterSelect,
  status,
  setScenarioFilter,
  username = "",
  connection,
}) => {
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isInviteOtherPeople, setIsInviteOtherPeople] = useState(false);
  const [isInputEmail, setIsInputEmail] = useState(true);
  const { data: jointAccountList } = useJointAccountList();
  const { enqueueSnackbar } = useSnackbar();
  const { setScenarioFilterData, setScenarioFilterOpen } = useContext(
    ScenarioItemContext
  );
  const [openDialog, setOpenDialog] = useState(false);
  const theme = useCompanyTheme();
  const { setSelectedScenarioId } = useContext(FinanceContext);

  const invalidateQueries = useQueryInvalidationWithNotification();

  const invalidateScenario = (scenarioId) => {
    invalidateQueries("getConnectionByScenario");
    setScenarioFilter({ scenarioId });
    setSelectedScenarioId(scenarioId);
    setScenarioFilterData(filterSelect);
    invalidateQueries("scenarioList");
    invalidateQueries(["scenarioProductList", scenarioId]);
    invalidateQueries(["maxLoanHistoryChartData", scenarioId]);
    invalidateQueries(["maxBuyingPowerHistoryChartData", scenarioId]);
    invalidateQueries(["buyingPowerHistoryByScenario", scenarioId]);
  };
  const _inviteUserToScenario = useMutation(
    (payload) => inviteUserToScenario(payload),
    {
      onSuccess: (result) => {
        invalidateScenario(result.scenarioId);
        setScenarioFilterOpen(false);
        enqueueSnackbar("Invitation Sent!", {
          variant: "success",
        });
      },
    }
  );
  const [openDeleteMemberModal, setOpenDeleteMemberModal] = useState(false);

  const removeJointScenarioMember = useMutation(
    (payload) => removeUserFromScenario(payload.scenarioId, payload.email),
    {
      onSuccess: (result) => {
        invalidateScenario(result.scenarioId || connection.scenarioId);
      },
    }
  );

  const handleRemoveMember = () => {
    removeJointScenarioMember.mutate(
      { scenarioId: connection.scenarioId, email: connection.email },
      {
        onSuccess: (result) => {
          enqueueSnackbar("Delete member successfully", {
            variant: "success",
          });
          setOpenDeleteMemberModal(false);
          invalidateScenario(result.scenarioId || connection.scenarioId);
        },
      }
    );
  };

  const handleInviteOtherPeople = (state) => {
    setIsInviteOtherPeople(state);
  };

  const handleSelectJointAccountMember = (memberEmail) => {
    handleChange(memberEmail);
    setIsInputEmail(false);
  };

  useEffect(() => {
    if (jointAccountList?.length === 0) {
      setIsInviteOtherPeople(true);
    }
  }, [jointAccountList?.length]);

  const userName = username ? `(${username})` : "";
  const displayName = `${value} ${userName}`;

  const generateContent = useMemo(() => {
    const handleChangeValue = (e) => {
      handleChange(e.target.value);
      setIsValidEmail(!checkEmailVerification(e.target.value));
    };
    const isAbleToInvite = isValidEmail && value;
    const removeMemberBtn = (
      <div
        className="hover-button"
        data-hover="Delete"
        onClick={() => setOpenDeleteMemberModal(true)}
      >
        <DeleteForeverIcon />
      </div>
    );
    const editMemberBtn = (
      <div
        className="hover-button"
        data-hover="Change email"
        onClick={() => {
          handleChange("");
          setIsInputEmail(true);
        }}
      >
        <EditIcon />
      </div>
    );
    if (status || !isInputEmail) {
      return (
        <div className="flex">
          <span>
            {displayName}
            {status === JointScenarioConnectionStatus.WAITING && (
              <Chip label="Waiting" size="small" />
            )}
          </span>{" "}
          {status ? removeMemberBtn : editMemberBtn}
        </div>
      );
    }

    return (
      <div>
        <div
          className="scenario-invite-input"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <ConfirmSendInvitation
            open={openDialog}
            handleDialogClose={setOpenDialog}
            data={{
              ...filterSelect,
              invitedEmails: [value],
            }}
            sendMutation={_inviteUserToScenario}
          />
          <TextField
            id="outlined-size-small"
            variant="outlined"
            type="email"
            size="small"
            className="scenario-invite-input__email"
            onChange={handleChangeValue}
            value={value}
            onBlur={handleBlur}
            placeholder="Email"
            style={{
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            }}
            inputProps={{
              autoComplete: "off",
            }}
            error={!isValidEmail}
            helperText={!isValidEmail ? "Invalid email" : ""}
          />
          {filterSelect?.scenarioId ? (
            <Button
              disabled={_inviteUserToScenario.isLoading || !isAbleToInvite}
              onClick={() => {
                if (!filterSelect.scenarioId) {
                  setOpenDialog(true);
                } else {
                  const data = {
                    ...filterSelect,
                    invitedEmails: [value],
                  };
                  delete data.connections;
                  _inviteUserToScenario.mutate(data);
                }
              }}
              variant="contained"
              style={{
                height: 40,
                boxShadow: "none",
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                maxWidth: "40px !important",
                minWidth: 40,
                padding: 0,
                background: isAbleToInvite ? theme.primaryColor : "gray",
              }}
            >
              {_inviteUserToScenario.isLoading ? (
                <LoadingSpinner />
              ) : (
                <SendIcon
                  color="white"
                  style={{ color: "white", fontSize: 14 }}
                />
              )}
            </Button>
          ) : (
            <Button
              disabled={_inviteUserToScenario.isLoading || !isAbleToInvite}
              onClick={() => {
                setIsInputEmail(false);
              }}
              variant="contained"
              style={{
                height: 40,
                boxShadow: "none",
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                maxWidth: "40px !important",
                minWidth: 40,
                padding: 0,
                background: isAbleToInvite ? theme.primaryColor : "gray",
              }}
            >
              {_inviteUserToScenario.isLoading ? (
                <LoadingSpinner />
              ) : (
                <CheckIcon
                  color="white"
                  style={{ color: "white", fontSize: 14 }}
                />
              )}
            </Button>
          )}
        </div>
        {jointAccountList.length > 0 && (
          <div
            className="select-joint-account-button"
            onClick={() => setIsInviteOtherPeople(false)}
          >
            Select from Joint Account
          </div>
        )}
      </div>
    );
  }, [
    _inviteUserToScenario,
    displayName,
    filterSelect,
    handleBlur,
    handleChange,
    isInputEmail,
    isValidEmail,
    jointAccountList.length,
    openDialog,
    status,
    theme.primaryColor,
    value,
  ]);

  return (
    <div className="scenario-invite-input-container">
      {jointAccountList?.length > 0 &&
      !isInviteOtherPeople &&
      isInputEmail &&
      !status ? (
        <JointAccountSelection
          handleInviteOtherPeople={handleInviteOtherPeople}
          handleChange={handleSelectJointAccountMember}
          currentConnection={filterSelect?.connections}
        />
      ) : (
        generateContent
      )}

      <ConfirmDeleteModal
        title={`Are you sure you to remove ${displayName} from this scenario?`}
        open={openDeleteMemberModal}
        handleCloseDialog={() => setOpenDeleteMemberModal(false)}
        header="Remove member from joint scenario"
        handleConfirm={handleRemoveMember}
        loading={removeJointScenarioMember.isLoading}
      />
    </div>
  );
};

export default ScenarioInviteInput;
